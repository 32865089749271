import React from "react";
import Header from "../Components/LayoutComponents/Header";
import Footer from "../Components/LayoutComponents/Footer";
import ViewModel from "../Components/LayoutComponents/ViewModel";

import Share from "../Components/LayoutComponents/Share";
import SectionFooter from "../Components/LayoutComponents/SectionFooter";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main className="doorverse_main_section">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 doorverse_view_model">
            <ViewModel />
          </div>
          <div className="w-full md:w-1/2 doorverse_right_section">
            <div className="doorverse_section">
              {children}
              <Share />
              <SectionFooter />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
