import axios from "axios";
import store from "Redux/Store";
import { showToast } from "Redux/General/Toast";

import CODES from "./StatusCodes";
import { USER_ID } from "./Common";

const METHOD = {
  GET: "get",
  PUT: "put",
  POST: "post",
  DELETE: "delete",
};

const BASEURL = process.env.REACT_APP_API_BASE_URL;

class Api {
  isLoggedIn = true;

  constructor() {
    this.baseURL = BASEURL;
    this.getAuthenticationInfo();
  }

  getAuthenticationInfo() {
    const token = localStorage.getItem(USER_ID) || "";
    if (token) {
      this.isLoggedIn = true;
      this.accessToken = token;
    }
  }

  get(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.GET, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  post(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.POST, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  put(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PUT, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  delete(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.DELETE, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  api(method, url, data) {
    return new Promise((resolve, reject) => {
      let axiosConfig = {};
      axiosConfig.method = method;

      axiosConfig.url = this.baseURL + url;

      axiosConfig.headers = this.setHeaders(data);
      if (data) {
        if (data.params) axiosConfig.params = data.params;

        if (data.data) axiosConfig.data = data.data;
      }

      axios(axiosConfig)
        .then((response) => {
          if (response.status === CODES.UNAUTHORIZED) {
          } else if (response.status === CODES.INTERNAL_SERVER_ERROR) {
            store.dispatch(
              showToast(response?.data?.message || "Internal Server Error")
            );
            if (data && data.skipErrorHandling) reject(response.data);
            return;
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => {
          console.error("ERROR", error);
          if (error?.response?.data?.message)
            store.dispatch(showToast(error?.response?.data?.message));
          reject(error);
        });
    });
  }

  setHeaders(data) {
    let headers = {};
    headers["accept-language"] = "en";
    headers["Content-Type"] = "application/json";

    if (data) {
      if (data.isMultipart) {
        headers["Content-Type"] = "multipart/form-data";
      }

      if (data.headers) {
        for (var key in data.headers) {
          if (data.headers.hasOwnProperty(key)) {
            headers[key] = data.headers[key];
          }
        }
      }
    }

    if (this.isLoggedIn !== false && !(data && data.skipAuth)) {
      headers["Authorization"] = this.accessToken;
    }

    return headers;
  }
}

export default Api;
