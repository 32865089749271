/* eslint-disable no-unused-vars */
import React from "react";
import premium from "../Assets/Images/premium.svg";
import standard from "../Assets/Images/standard.svg";
import s_cctv from "../Assets/Images/Standard Amenities/cctv.svg";
import p_temple from "../Assets/Images/Premium Amenities/TEMPLE.svg";
import s_gazebo from "../Assets/Images/Standard Amenities/Gazebo.svg";
import p_cricket from "../Assets/Images/Premium Amenities/cricket.svg";
import s_club from "../Assets/Images/Standard Amenities/Club house.svg";
import p_swimming from "../Assets/Images/Premium Amenities/swimming pool.svg";
import p_landscape from "../Assets/Images/Premium Amenities/landscape garden.svg";
import s_children from "../Assets/Images/Standard Amenities/children play area.svg";
import s_alloted from "../Assets/Images/Standard Amenities/alloted car parking.svg";

const AmmenitiesComponent = () => {
  const premium_ammenities = [
    { name: "Landscape Garden", image: p_landscape },
    { name: "Swimming Pool", image: p_swimming },
    { name: "Multi pupose sports box", image: p_cricket },
    { name: "Temple", image: p_temple },
  ];
  const standard_ammenities = [
    { name: "Children Play Area", image: s_children },
    { name: "Alloted Car Parking", image: s_alloted },
    { name: "CCTV Surveillance", image: s_cctv },
    { name: "Gazebo", image: s_gazebo },
    { name: "Club House", image: s_club },
  ];
  return (
    <div className="flex flex-col">
      <div className="doorverser_premium_ammenities mb-[60px]">
        <div className="flex items-center gap-5 mb-6">
          <img src={premium} alt="premium" />
          <span className="text-2xl font-medium text-[#F59400]">Premium</span>
        </div>
        <div className="flex flex-wrap">
          {premium_ammenities.map((s, index) => (
            <div
              className="flex flex-col items-center gap-4 w-1/3 md:w-1/4 mb-5"
              key={index}
            >
              <div className="w-[40px] h-[40px]">
                <img src={s.image} alt={s.name} className="w-full h-full" />
              </div>
              <span className=" text-sm font-normal text-center">{s.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="doorverser_standard_ammenities">
        <div className="flex items-center gap-5 mb-6">
          <img src={standard} alt="standard" />
          <span className="text-2xl font-medium text-[#4B5563]">Standard</span>
        </div>
        <div className="flex flex-wrap">
          {standard_ammenities.map((s, index) => (
            <div
              className="flex flex-col items-center gap-4 w-1/3 md:w-1/4 mb-5"
              key={index}
            >
              <div className="w-[40px] h-[40px]">
                <img src={s.image} alt={s.name} className="w-full h-full" />
              </div>
              <span className=" text-sm font-normal text-center">{s.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AmmenitiesComponent;
