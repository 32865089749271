import { useLoader, useThree } from "@react-three/fiber";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader.js";

function BlockJModel() {
  const three = useThree();
  const model = useLoader(
    GLTFLoader,
    "https://doorverse-property.s3.ap-south-1.amazonaws.com/Om-courtyard-24-9-2023/Block_J/Block_J.gltf",
    (loader) => {
      const ktx2Loader = new KTX2Loader();
      ktx2Loader.setTranscoderPath(
        "https://doorverse-common-files.s3.ap-south-1.amazonaws.com/basis/"
      );
      ktx2Loader.detectSupport(three.gl);
      loader.setKTX2Loader(ktx2Loader);
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath(
        "https://www.gstatic.com/draco/versioned/decoders/1.5.6/"
      );
      loader.setDRACOLoader(dracoLoader);
    }
  );
  model.scene.traverse((child) => {
    if (child.isMesh && child.material.isMeshStandardMaterial) {
      child.material.envMapIntensity = 2.6;
      child.material.needsUpdate = true;
    }
  });

  return (
    <>
      <primitive object={model.scene} rotation-y={2.2} />
    </>
  );
}
export default BlockJModel;
