import React from "react";
import Layout from "./Layout";
import SpecificationComponent from "../Components/SpecificationComponent";

const Specifications = () => {
  return (
    <Layout>
      <section className="md:h-[655px] mb-[60px] md:mb-[56px] md:mr-[35px]">
        <SpecificationComponent />
      </section>
    </Layout>
  );
};

export default Specifications;
