/* eslint-disable jsx-a11y/iframe-has-title */
import Slider from "react-slick";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import { Box, Dialog } from "@mui/material";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Link } from "react-router-dom";
import { getImageList } from "Helpers/Common";
import Model from "../../Assets/Images/model.svg";
import ButtonNext from "../../Assets/Images/button-next.svg";
import ButtonClose from "../../Assets/Images/button-close.svg";
import ButtonPrevious from "../../Assets/Images/button-previous.svg";

const images = getImageList(require.context("../../Assets/Images/Slider"));
const modelImages = getImageList(
  require.context("../../Assets/Images/slider-modal")
);

const ImageViewModal = styled(Dialog)`
  .root {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
    margin: 0 !important;
    background-color: rgba(0, 0, 0, 0.8) !important;

    @media (max-width: 960px) {
      // .modal-width {
      //   transform: rotate(90deg);
      //   width: 100%;
      // }

      .modal-container {
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
    }

    @media (max-width: 1024px) {
      .btn-close {
        bottom: 4% !important;
      }

      .image-modal {
        width: 70% !important;
        margin: auto;
      }

      .slick-track {
        height: auto !important;
      }

      .slider-image {
        height: auto !important;
      }

      .slick-slide {
        height: 100vh;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0 2px;
      }

      .slick-prev {
        left: -50px !important;
      }

      .slick-next {
        right: -50px !important;
      }
    }

    .image-modal {
      width: 80%;
      margin: auto;
    }

    .slick-track {
      height: calc(100vh - 100px);
    }

    .slider-image {
      margin: 5% auto 0;
      height: calc(100vh - 100px);
    }

    .btn-close {
      position: absolute;
      bottom: 6%;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
`;

const settings = {
  speed: 500,
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <>
      <img
        alt="next"
        src={ButtonNext}
        onClick={onClick}
        style={{ ...style, height: "42px", width: "40px" }}
        className={className}
      />
    </>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <>
      <img
        alt="prev"
        onClick={onClick}
        src={ButtonPrevious}
        style={{ ...style, height: "42px", width: "40px" }}
        className={className}
      />
    </>
  );
}

const ViewModel = () => {
  const [openModal, setModalState] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const handleOpenModel = () => {
    if (isDesktop) {
      setModalState(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Assuming 768px as the threshold for desktop
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col-reverse md:flex-col items-center w-full">
        <div className="flex flex-col mt-4 md:mt-0 md:flex-row items-center gap-6 lg:gap-0 w-full justify-between md:mb-[34px] font-normal md:hidden pb-4">
          <div>
            <Link
              to="/3d-model/exterior/layout"
              className="primary_button flex"
            >
              <img src={Model} alt="" />
              <b>View 3D Model</b>
            </Link>
          </div>
        </div>
        <div className="flex items-center w-full">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="viewModelSwiper"
          >
            {images.length &&
              images.map((image, i) => (
                <SwiperSlide key={image + i}>
                  <img
                    src={image}
                    alt={image + i}
                    className="cursor"
                    onClick={handleOpenModel}
                  />
                </SwiperSlide>
              ))}
            <SwiperSlide>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3674.4730402682526!2d72.6495056!3d22.932799599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e898a59a3b917%3A0xe6f15a0f29b41994!2sOm%20Courtyard%20Bungalows!5e0!3m2!1sen!2sin!4v1709395619597!5m2!1sen!2sin"
                width="600"
                height="850"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {modelImages && (
        <ImageViewModal
          open={openModal}
          classes={{ paper: "root" }}
          onClose={() => setModalState(false)}
        >
          <React.Fragment>
            <Box className="flex">
              <Box className="image-modal modal-width">
                <Slider {...settings}>
                  {modelImages.map((image, i) => (
                    <div key={`${image}${i}`}>
                      <img src={image} alt="View" className="slider-image" />
                    </div>
                  ))}
                </Slider>
                <img
                  src={ButtonClose}
                  alt="next"
                  className="cursor btn-close"
                  onClick={() => setModalState(false)}
                />
              </Box>
            </Box>
          </React.Fragment>
        </ImageViewModal>
      )}
    </>
  );
};

export default ViewModel;
