import React from "react";
import breifcase from "../Assets/Images/briefcase.svg";
import hospital from "../Assets/Images/Frame.svg";
import transport from "../Assets/Images/bus.svg";
import commercial from "../Assets/Images/map.svg";

const ConnectivityComponent = () => {
  return (
    <div>
      <div className="flex flex-col mb-[30px]">
        <div className="flex gap-2 items-center mb-5">
          <img src={breifcase} alt="" />
          <span className="text-xl">Schools & Colleges</span>
        </div>
        <ul className="list-disc ml-6">
          <li>Navoday Vidhyalay - 0.5 Km</li>
          <li>Divine School - 1 Km</li>
          <li>Anand Niketan School - 3 Km</li>
          <li>Swaminarayan school</li>
          <li>Adharshila school</li>
        </ul>
      </div>
      <div className="flex flex-col">
        <div className="flex gap-2 items-center mb-5">
          <img src={hospital} alt="" />
          <span className="text-xl">Hospital</span>
        </div>
        <ul className="list-disc ml-6 mb-[30px]">
          <li>Shalin multispeciality Hospital - 1 Km</li>
          <li>Shreeji Multispeciality Hospital and Research Center - 2 Km</li>
          <li>Urban Health Center - 2 Km</li>
        </ul>
      </div>
      <div className="flex flex-col">
        <div className="flex gap-2 items-center mb-5">
          <img src={transport} alt="" />
          <span className="text-xl">Transportation</span>
        </div>
        <ul className="list-disc ml-6 mb-[30px]">
          <li>Vinoba Bus Stand - 0.2 Km</li>
          <li>Auto Stand - 0.2 Km</li>
          <li>Geratpur Railway Station - 2 Km</li>
          <li>Vastral Metro Station - 8 Km</li>
        </ul>
      </div>
      <div className="flex flex-col">
        <div className="flex gap-2 items-center mb-5">
          <img src={commercial} alt="" />
          <span className="text-xl">Shopping & Commercial</span>
        </div>
        <ul className="list-disc ml-6 mb-[30px]">
          <li>Croma Store - 2 Km</li>
          <li>Zudio Store - 2 Km</li>
          <li>D mart Mall - 6 Km</li>
          <li>Om County Commercial - 0.1 Km</li>
        </ul>
      </div>
    </div>
  );
};

export default ConnectivityComponent;
