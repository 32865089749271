import { createSlice } from "@reduxjs/toolkit";

const initState = {
  enable: false,
  message: "",
};

export const slice = createSlice({
  name: "Auth",
  initialState: { ...initState },
  reducers: {
    showToast: (state, action) => {
      state.enable = true;
      state.message = action.payload;
    },
    hideToast: (state, action) => {
      state.enable = false;
      state.message = "";
    },
  },
});

export const { showToast, hideToast } = slice.actions;
export default slice.reducer;
