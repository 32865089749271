import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "@react-three/drei";
import { manageLoading } from "Redux/General/Loader";
import LoaderLogo from "../../../Assets/Images/DOORVERSE.png";

function CustomLoader() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(manageLoading(true));

    return () => {
      dispatch(manageLoading(false));
    };
  }, [dispatch]);

  return (
    <>
      <div className="ctm-loader">
        <div style={{ color: "#fff" }}>
          <img src={LoaderLogo} alt="LoaderLogo" className="loader-logo" />
          <Loader />
        </div>
      </div>
    </>
  );
}

export default CustomLoader;
