import { Environment } from "@react-three/drei";

function Env() {
  return (
    <>
      <Environment
        files={
          "https://doorverse-property.s3.ap-south-1.amazonaws.com/DaySkyHDRI015A_1K-HDR+.exr"
        }
      />
    </>
  );
}

export default Env;
