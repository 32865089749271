import React, { useEffect } from "react";
import { Tab, initTE } from "tw-elements";

const prodSpecification = {
  Construction: [
    "Smooth finish exterior plaster with weather proof color.",
    "Interior plaster with white putty.",
    "In sloping roof, the fabrication will be framed and roof tiles will be installed.",
  ],
  Flooring: [
    "Standard quality vetrified tiles for all rooms and kitchen.",
    "All bathrooms and kitchen will be covered with ceramic tiles up to lintel level.",
    "Granite platform in kitchen",
  ],
  "Doors and Windows": [
    "Attractive main door with branded lock and other wooden doors.",
    "Glass window with aluminium frame.",
  ],
  Plumbing: [
    "Concealed PVC plumbing and branded CP fittings will be installed in all bathrooms.",
  ],
  Electrical: [
    "Branded company accessories with concealed safe copper wiring.",
  ],
};

const SpecificationComponent = () => {
  const envName = process.env.REACT_APP_ENVIRONMENT || "dev";

  useEffect(() => {
    initTE({ Tab });
  }, []);

  return (
    <div>
      {envName === "prod" ? (
        <>
          {Object.keys(prodSpecification).map((heading) => (
            <div className="mb-3">
              <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
                {heading}:
              </h2>
              <ul class="space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                {prodSpecification[heading].map((item) => (
                  <li key={item} style={{ marginLeft: "16px" }}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </>
      ) : (
        <>
          <ul
            className="mb-10 flex list-none flex-row flex-wrap border-b-0 pl-0 doorverse-tabs"
            role="tablist"
            data-te-nav-ref
          >
            <li role="presentation">
              <a
                href="#tabs-building"
                className="block text-center px-3 md:px-7 py-4 text-sm md:text-base font-normal leading-tight text-[#232735] hover:isolate hover:border-transparent rounded-[58px] hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:font-bold data-[te-nav-active]:bg-white dark:hover:bg-transparent mr-1"
                data-te-toggle="pill"
                data-te-target="#tabs-building"
                data-te-nav-active
                role="tab"
                aria-controls="tabs-building"
                aria-selected="true"
              >
                Building Specification
              </a>
            </li>
            <li role="presentation">
              <a
                href="#tabs-unit"
                className="block text-center px-3 md:px-7 py-4 text-sm md:text-base font-normal leading-tight text-[#232735] hover:isolate hover:border-transparent rounded-[58px] hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:font-bold data-[te-nav-active]:bg-white dark:hover:bg-transparent ml-1"
                data-te-toggle="pill"
                data-te-target="#tabs-unit"
                role="tab"
                aria-controls="tabs-unit"
                aria-selected="false"
              >
                Unit Specification
              </a>
            </li>
          </ul>

          <div className="mb-6">
            <div
              className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
              id="tabs-building"
              role="tabpanel"
              aria-labelledby="tabs-building-tab"
              data-te-tab-active
            >
              <ul className="list-disc ml-6 mb-[30px]">
                <li>Earthquake Resistant Structural System</li>
                <li>R.C.C. Trimix Road</li>
                <li>CCTV Camera </li>
                <li>Attractive Street Light</li>
              </ul>
            </div>
            <div
              className="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
              id="tabs-unit"
              role="tabpanel"
              aria-labelledby="tabs-unit-tab"
            >
              <div className="md:h-[580px] md:overflow-y-scroll">
                <ul className="list-disc ml-6 mb-[30px]">
                  <li>
                    All outer wall finished in double coat plaster with texture.
                  </li>
                  <li>
                    All internal wall finished with single coat plaster with
                    wall putti.
                  </li>
                  <li>Good make vitrified floor tiles.</li>
                  <li>Designer wall tiles.</li>
                  <li>Granite kitchen platform with quality S.S. sink </li>
                  <li>
                    All rooms provided with split air conditioner piping core.
                  </li>
                  <li>Good quality wooden door frames with flush door.</li>
                  <li>Good quality powder coated aluminium section windows.</li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SpecificationComponent;
