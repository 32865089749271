/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import fb from "../../Assets/Images/fb.svg";
import ig from "../../Assets/Images/ig.svg";
import web from "../../Assets/Images/web.svg";
import pin from "../../Assets/Images/pin.svg";

const SectionFooter = () => {
  return (
    <div className="flex flex-col md:flex-row gap-5 md:gap-0 doorverse_section_footer items-center">
      <div className="flex flex-col md:flex-row md:w-2/3 items-center gap-2 md:gap-4">
        <img src={pin} alt="pin" className="w-[25px] h-[25px] cursor-pointer" />
        <span className="text-center lg:text-left">
          Turn From - Railway Bridge / Hotel Anand Palace, Nr. Hathijan Circle,
          Vinzol, Ahmedabad - 382445, Contact us: +91 9941191411
        </span>
      </div>
      <div className="flex md:w-1/3 justify-end items-center gap-4">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/amrutinfraconnew"
        >
          <img src={fb} alt="fb" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/amrutinfracon?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        >
          <img src={ig} alt="ig" />
        </a>
        <img
          src={web}
          alt="web"
          className="cursor-pointer"
          onClick={() => window.open("https://www.amrutinfracon.com")}
        />
      </div>
    </div>
  );
};

export default SectionFooter;
