import * as Yup from "yup";
import { Formik } from "formik";
import { styled } from "styled-components";
import countryCodes from "country-codes-list";
import React, { useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  TextField,
  Autocomplete,
  FormHelperText,
} from "@mui/material";

import Api from "Helpers/Api";
import VerifyOtp from "./VerifyOtp";
import API_PATH from "Helpers/UrlPaths";
import CircularLoader from "Components/Loader";

const ContactVerificationWrapper = styled(Dialog)`
  .root {
    width: 50%;
    border-radius: 20px;
    padding: 20px;
    max-width: 400px;

    @media (max-width: 960px) {
      width: 80%;
    }
    @media (max-width: 600px) {
      width: 90%;
    }
  }
  .autocomplete {
    min-width: 30%;
  }
  .number {
    width: 100%;
    /* margin: 0 0 0 10px; */
  }
  .spacing {
    margin-right: 30px;
  }

  .cancelBtn {
    border: 1px solid lightgray;
    color: #000;
  }
  .sendOtpBtn {
    background: linear-gradient(263.61deg, #fc0de1 -69.43%, #ffab2b 104.92%);
    color: #fff;
    margin: 0 0 0 10px;

    &.disabled {
      background: lightgray;
    }
  }
`;

const CountryCodesObject = countryCodes.customList(
  "countryCode",
  "+{countryCallingCode}"
);

const EnterYourContactNumber = ({ setModalState, countryCodes, ...props }) => {
  return (
    <React.Fragment>
      <Box className="mb-5">Please verify your contact number:</Box>

      <Box className="flex">
        <Autocomplete
          key="countryCode"
          name="countryCode"
          disabled
          className="autocomplete"
          options={countryCodes}
          onChange={(e, value) => props.setFieldValue("countryCode", value)}
          defaultValue={props?.values?.countryCode}
          clearIcon={false}
          renderInput={(params) => (
            <TextField {...params} label="Country" size="small" />
          )}
        />
        <Box style={{ width: "100%", margin: "0 0 0 10px" }}>
          <TextField
            key="phone"
            name="phone"
            type="number"
            label="Contact No."
            size="small"
            classes={{ root: "number" }}
            onChange={(e) => props.setFieldValue("phone", e.target.value)}
            defaultValue={props?.values?.phone}
          />

          <FormHelperText error={true} style={{ height: "18px" }}>
            {props?.errors.phone || ""}
          </FormHelperText>
        </Box>
      </Box>
      <Box className="flex mt-5 justify-end	 w-100">
        <Button className="cancelBtn" onClick={() => setModalState(false)}>
          Cancel
        </Button>
        <Button className="sendOtpBtn" type="submit">
          Send OTP
        </Button>
      </Box>
    </React.Fragment>
  );
};

const initialValues = {
  countryCode: "+91",
  phone: "",
};

const validationSchema = Yup.object({
  countryCode: Yup.string().required("Please select country code"),
  phone: Yup.string()
    .required("Please enter phone number")
    .min(10, "please enter valid phone number")
    .max(10, "please enter valid phone number"),
});

const ContactVerification = ({ open = true }) => {
  const formRef = useRef(null);
  const [otp, setOtp] = useState(null);
  const [openModal, setModalState] = useState(open);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isOtpSent, setIsOtpSentStatus] = useState(false);

  const countryCodes = Object.values(CountryCodesObject);

  const API = useMemo(() => new Api(), []);
  const [loading, setLoading] = useState(false);
  const [requiredDetails, setRequiredDetails] = useState(null);

  const handleSubmit = async (values) => {
    setLoading(true);
    const contact = parseInt(values.phone);
    setPhoneNumber(contact);

    let response = await API.post(API_PATH.SEND_OTP, {
      data: {
        countryCode: "+91",
        phoneNumber: contact,
      },
    });

    if (response) {
      setRequiredDetails(response?.data);
      setLoading(false);
      setIsOtpSentStatus(true);
    }
  };

  return (
    <ContactVerificationWrapper
      open={openModal}
      classes={{ paper: "root" }}
      onClose={() => setModalState(false)}
    >
      <CircularLoader open={loading} />
      {!isOtpSent ? (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form ref={formRef} onSubmit={handleSubmit}>
              <EnterYourContactNumber
                countryCodes={countryCodes}
                setModalState={setModalState}
                handleChange={handleChange}
                values={values}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
              />
            </form>
          )}
        </Formik>
      ) : (
        <VerifyOtp
          otp={otp}
          phoneNumber={phoneNumber}
          setOtp={setOtp}
          formValues={formRef?.current?.values}
          setIsOtpSentStatus={setIsOtpSentStatus}
          requiredDetails={requiredDetails}
          setModalState={setModalState}
        />
      )}
    </ContactVerificationWrapper>
  );
};

export default ContactVerification;
