import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { Suspense, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrbitControls, Html } from "@react-three/drei";

import Env from "./Env.jsx";
import Layout from "Pages/3dModel/Layout.jsx";
import CustomLoader from "./CustomLoader.jsx";
import BlockGHIModel from "./BlockGHIModel.jsx";
import { showHideBlockModel } from "Redux/General/ManageTag.js";
import { DEFAULT_ROTATE, ROTATE_DIFF } from "Helpers/Common.js";

const BLOCK_TAGS = [
  { name: "H1", position: [14, 7, 6] },
  { name: "H2", position: [11, 7, 0] },
  { name: "H3", position: [7, 7, -5] },
  { name: "H4", position: [4, 7, -9] },
  { name: "H5", position: [0, 7, -15] },
  { name: "H6", position: [-13, 7, -6] },
  { name: "H7", position: [-8, 7, -1] },
  { name: "H8", position: [-5, 7, 3] },
  { name: "H9", position: [-1, 7, 9] },
  { name: "H10", position: [3, 7, 15] },
];

function BlockH() {
  const controls = useRef();
  const dispatch = useDispatch();
  const [rotateDegree, setRotationDegree] = useState(DEFAULT_ROTATE);
  const { isLabelShow } = useSelector((state) => state.ManageTag);

  return (
    <>
      <Layout rotateDegree={rotateDegree}>
        <Suspense fallback={<CustomLoader />}>
          <Canvas
            gl={{ outputColorSpace: THREE.SRGBColorSpace }}
            camera={{ fov: 45, near: 0.5, far: 200, position: [40, -15, -5] }}
          >
            <OrbitControls
              enableDamping={false}
              makeDefault
              maxDistance={50}
              minDistance={25}
              maxPolarAngle={Math.PI * 0.45}
              ref={controls}
              onChange={() => {
                const radians = controls.current.getAzimuthalAngle();
                setRotationDegree(radians * (180 / Math.PI) - ROTATE_DIFF);
              }}
            />
            <Env />
            <BlockGHIModel />

            {isLabelShow ? (
              BLOCK_TAGS.length > 0 &&
              BLOCK_TAGS.map((item) => (
                <Html
                  position={item.position}
                  key={item.name}
                  wrapperClass="Block_label"
                >
                  <span onClick={() => dispatch(showHideBlockModel(item.name))}>
                    {item.name}
                  </span>
                </Html>
              ))
            ) : (
              <></>
            )}

            {/* <Html position={[14, 7, 6]} wrapperClass="Block_label">
              H1
            </Html>
            <Html position={[11, 7, 0]} wrapperClass="Block_label">
              H2
            </Html>
            <Html position={[7, 7, -5]} wrapperClass="Block_label">
              H3
            </Html>
            <Html position={[4, 7, -9]} wrapperClass="Block_label">
              H4
            </Html>
            <Html position={[0, 7, -15]} wrapperClass="Block_label">
              H5
            </Html>
            <Html position={[-13, 7, -6]} wrapperClass="Block_label">
              H6
            </Html>
            <Html position={[-8, 7, -1]} wrapperClass="Block_label">
              H7
            </Html>
            <Html position={[-5, 7, 3]} wrapperClass="Block_label">
              H8
            </Html>
            <Html position={[-1, 7, 9]} wrapperClass="Block_label">
              H9
            </Html>
            <Html position={[3, 7, 15]} wrapperClass="Block_label">
              H10
            </Html> */}
          </Canvas>
        </Suspense>
      </Layout>
    </>
  );
}

export default BlockH;
