import React, { useState } from "react";

import Layout from "Pages/3dModel/Layout";
import CustomLoader from "./CustomLoader";

const AmenitiesPage = () => {
  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false); // Hide loader once iframe has loaded
  };

  return (
    <Layout>
      <div
        style={{
          position: "relative",
          height: "calc(100vh - 87px)",
          width: "100%",
        }}
      >
        {loading && <CustomLoader />}{" "}
        {/* Show loader while iframe is loading */}
        <iframe
          title="Amenities"
          style={{ height: "100%", width: "100%", border: "none" }}
          src="https://om-courtyard-amenities3d-10-08-2024.s3.ap-south-1.amazonaws.com/index.htm"
          onLoad={handleIframeLoad} // Set loading to false when iframe loads
        ></iframe>
      </div>
    </Layout>
  );
};

export default AmenitiesPage;
