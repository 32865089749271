import React, { useEffect } from "react";
import { Modal, Ripple, initTE } from "tw-elements";

import ShareInner from "./ShareInner";
import share from "../../Assets/Images/share.svg";

const Share = () => {
  useEffect(() => {
    initTE({ Modal, Ripple });
  }, []);

  return (
    <div>
      <button
        type="button"
        data-te-toggle="modal"
        data-te-target="#ShareModal"
        data-te-ripple-init
        data-te-ripple-color="light"
        className="doorverse_share  md:flex gap-2"
      >
        <span className="hidden md:inline"> Share </span>
        <img src={share} alt="" />
      </button>
      {/* <!--Share centered modal--> */}
      <div
        data-te-modal-init
        className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none doorverse-share-modal"
        id="ShareModal"
        tabIndex="-1"
        aria-labelledby="ShareModalTitle"
        aria-modal="true"
        role="dialog"
      >
        <div
          data-te-modal-dialog-ref
          className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[568px] mx-6 "
        >
          <div className="pointer-events-auto relative flex w-full flex-col items-center rounded-[30px] border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            {/* <!--Modal body--> */}
            <ShareInner copyText={window.location.origin} />
            {/* <!--Modal body--> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
