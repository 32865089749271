import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Sidenav, Modal, Ripple, initTE } from "tw-elements";

import Model from "../../Assets/Images/model.svg";
import Close from "../../Assets/Images/close.svg";
import mobilemenu from "../../Assets/Images/menu.svg";
import Logo from "../../Assets/Images/amrutinfracon-logo.png";

const Header = () => {
  const location = useLocation();

  useEffect(() => {
    initTE({ Modal, Ripple, Sidenav });
  }, []);

  return (
    <>
      <header className="doorverse-header bg-[#F3F4F6]">
        <nav className="hidden lg:flex px-[70px] py-[15px] justify-between items-center">
          <div>
            <img src={Logo} alt="Logo" />
          </div>
          <div className="flex">
            <ul className="hidden lg:flex items-center">
              <li
                className={
                  !location.pathname.includes("connectivity") &&
                  !location.pathname.includes("amenities") &&
                  !location.pathname.includes("specifications") &&
                  !location.pathname.includes("contact")
                    ? "active"
                    : ""
                }
              >
                <Link to={`/`}>Home</Link>
              </li>
              <li
                className={
                  location.pathname.includes("connectivity") ? "active" : ""
                }
              >
                <Link to={`/connectivity`}>Connectivity</Link>
              </li>
              <li
                className={
                  location.pathname.includes("specifications") ? "active" : ""
                }
              >
                <Link to={`/specifications`}>Specifications</Link>
              </li>
              <li
                className={
                  location.pathname.includes("contact") ? "active" : ""
                }
              >
                <Link to={`/contact`}>Contact us</Link>
              </li>
              <li
                className={
                  location.pathname.includes("amenities-3d") ? "active" : ""
                }
              >
                <Link to={`/3d-model/amenities-3d`}>Amenities 3D</Link>
              </li>
              <li
                className={
                  location.pathname.includes("contact") ? "active" : ""
                }
              >
                <Link
                  to="/3d-model/exterior/layout"
                  className="primary_button flex"
                >
                  <img src={Model} alt="" />
                  View 3D Model
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        <nav className="flex lg:hidden px-[24px] py-[15px] justify-between items-center">
          <button
            className="flex lg:hidden items-center w-auto"
            data-te-sidenav-toggle-ref
            data-te-target="#sidenav-menu"
            aria-controls="#sidenav-menu"
            aria-haspopup="true"
          >
            <img src={mobilemenu} alt="" />
          </button>
          <div className="w-full">
            <img src={Logo} alt="Logo" className="flex my-0 mx-auto" />
          </div>
        </nav>
      </header>
      {/* SideNav Menu */}
      <nav
        id="sidenav-menu"
        className="absolute left-0 top-0 z-[1035] h-full w-full -translate-x-full overflow-hidden bg-white shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-zinc-800 doorverse-mobile-nav"
        data-te-sidenav-init
        data-te-sidenav-hidden="true"
        data-te-sidenav-position="absolute"
      >
        <div
          className="flex flex-row px-6 justify-between bg-[#F3F4F6]"
          style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
        >
          <div style={{ display: "contents" }}>
            <img src={Logo} alt="Logo" className="mx-auto" />
          </div>
          <div style={{ display: "grid" }}>
            <button
              type="button"
              className="box-content rounded-none border-none opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              data-te-sidenav-toggle-ref
              data-te-target="#sidenav-menu"
            >
              <img
                src={Close}
                className="rotate-180 w-6 h-6"
                alt="Close Button"
              />
            </button>
          </div>
        </div>
        <div className="flex">
          <ul className="flex flex-col w-full h-[calc(100vh-9rem)] pt-6 pb-4 px-6 gap-6 ">
            <li className="active">
              <Link to={`/`}>Home</Link>
            </li>
            <li className="">
              <Link to={`/connectivity`}>Connectivity</Link>
            </li>
            <li>
              <Link to={`/specifications`}>Specifications</Link>
            </li>
            <li>
              <Link to={`/3d-model/amenities-3d`}>Amenities 3D</Link>
            </li>
            <li className="flex">
              <Link to={`/contact`} className="primary_button">
                Contact us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      {/* SideNav Menu */}
    </>
  );
};

export default Header;
