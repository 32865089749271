import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { Suspense, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrbitControls, Html } from "@react-three/drei";

import Env from "./Env.jsx";
import BlockJModel from "./BlockJModel.jsx";
import Layout from "Pages/3dModel/Layout.jsx";
import CustomLoader from "./CustomLoader.jsx";
import { showHideBlockModel } from "Redux/General/ManageTag.js";
import { DEFAULT_ROTATE, ROTATE_DIFF } from "Helpers/Common.js";

const BLOCK_TAGS = [
  { name: "J1", position: [14, 7, 10] },
  { name: "J2", position: [11, 7, 5] },
  { name: "J3", position: [9, 7, 1] },
  { name: "J4", position: [7, 7, -2] },
  { name: "J5", position: [5, 7, -5] },
  { name: "J6", position: [1, 5, -9] },
  { name: "J7", position: [-14, 5, 2] },
  { name: "J8", position: [-10, 7, 6] },
  { name: "J9", position: [-8, 7, 9] },
  { name: "J10", position: [-5, 7, 13] },
  { name: "J11", position: [-3, 7, 16] },
  { name: "J12", position: [0, 7, 20] },
];

function BlockJ() {
  const controls = useRef();
  const dispatch = useDispatch();
  const [rotateDegree, setRotationDegree] = useState(DEFAULT_ROTATE);
  const { isLabelShow } = useSelector((state) => state.ManageTag);

  return (
    <>
      <Layout rotateDegree={rotateDegree}>
        <Suspense fallback={<CustomLoader />}>
          <Canvas
            gl={{ outputColorSpace: THREE.SRGBColorSpace }}
            camera={{ fov: 45, near: 0.5, far: 200, position: [40, -15, -5] }}
          >
            <OrbitControls
              enableDamping={false}
              makeDefault
              maxDistance={50}
              minDistance={25}
              maxPolarAngle={Math.PI * 0.45}
              ref={controls}
              onChange={() => {
                const radians = controls.current.getAzimuthalAngle();
                setRotationDegree(radians * (180 / Math.PI) - ROTATE_DIFF);
              }}
            />
            <Env />
            <BlockJModel />

            {isLabelShow ? (
              BLOCK_TAGS.length > 0 &&
              BLOCK_TAGS.map((item) => (
                <Html
                  position={item.position}
                  key={item.name}
                  wrapperClass="Block_label"
                >
                  <span onClick={() => dispatch(showHideBlockModel(item.name))}>
                    {item.name}
                  </span>
                </Html>
              ))
            ) : (
              <></>
            )}

            {/* <Html position={[14, 7, 10]} wrapperClass="Block_label">
              J1
            </Html>
            <Html position={[11, 7, 5]} wrapperClass="Block_label">
              J2
            </Html>
            <Html position={[9, 7, 1]} wrapperClass="Block_label">
              J3
            </Html>
            <Html position={[7, 7, -2]} wrapperClass="Block_label">
              J4
            </Html>
            <Html position={[5, 7, -5]} wrapperClass="Block_label">
              J5
            </Html>
            <Html position={[1, 5, -9]} wrapperClass="Block_label">
              J6
            </Html>
            <Html position={[-14, 5, 2]} wrapperClass="Block_label">
              J7
            </Html>
            <Html position={[-10, 7, 6]} wrapperClass="Block_label">
              J8
            </Html>
            <Html position={[-8, 7, 9]} wrapperClass="Block_label">
              J9
            </Html>
            <Html position={[-5, 7, 13]} wrapperClass="Block_label">
              J10
            </Html>
            <Html position={[-3, 7, 16]} wrapperClass="Block_label">
              J11
            </Html>
            <Html position={[0, 7, 20]} wrapperClass="Block_label">
              J12
            </Html> */}
          </Canvas>
        </Suspense>
      </Layout>
    </>
  );
}

export default BlockJ;
