import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Toast from "Components/Toast/Toast";
import { hideToast } from "Redux/General/Toast";
import { manageSession } from "Redux/General/Auth";
import { mobileDevicePattern, PAGES, SESSION_ID } from "Helpers/Common";

const EventManager = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isMobileDevice = useMemo(
    () => mobileDevicePattern.test(navigator.userAgent),
    []
  );

  const handleCloseToast = () => dispatch(hideToast());

  const toast = useSelector((state) => state.Toast);
  const { unitName } = useSelector((state) => state.ManageTag);
  const { userId, sessionId, geoLocation } = useSelector((state) => state.Auth);

  const [currPage, setCurrPage] = useState("");
  const [prevPage, setPrevPage] = useState("");

  const socket = useMemo(() => {
    if (!userId) return null;

    return io(process.env.REACT_APP_SOCKET_URL, {
      query: {
        userId,
        sessionId,
        geoLocation: JSON.stringify(geoLocation),
        viewType: isMobileDevice ? "Mobile" : "Web",
      },
    });
  }, [userId, sessionId, geoLocation, isMobileDevice]);

  useEffect(() => {
    if (!socket) return;

    socket.on("manageSession", (session_id) => {
      localStorage.setItem(SESSION_ID, session_id);
      dispatch(manageSession(session_id));
    });
  }, [socket, dispatch]);

  useEffect(() => {
    if (!socket) return;

    if (unitName) {
      socket.emit("unitName", { unitName, currPage });
    }
  }, [socket, unitName, currPage]);

  useEffect(() => {
    if (socket && !sessionId && !isMobileDevice)
      socket.emit("manageSession", {
        geoLocation,
        viewType: isMobileDevice ? "Mobile" : "Web",
      });
  }, [socket, sessionId, isMobileDevice, geoLocation]);

  useEffect(() => {
    if (!socket) return;

    if (prevPage !== currPage) {
      socket.emit("message", { prevPage, currPage });
      setPrevPage(() => currPage);
    }
  }, [socket, prevPage, currPage]);

  useEffect(() => {
    let pageName = location.pathname.split("/").pop();

    setCurrPage(() => (PAGES[pageName] || pageName).toUpperCase());
  }, [location.pathname]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.returnValue = "Are you sure you want to leave?";
      if (socket && !isMobileDevice) socket.disconnect();
    };

    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [socket, isMobileDevice]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        localStorage.removeItem(SESSION_ID);

        if (socket && isMobileDevice) socket.disconnect();
      }
    };

    if (isMobileDevice)
      document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (isMobileDevice) {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      }
    };
  }, [socket, isMobileDevice, dispatch]);

  return (
    <Toast
      open={toast.enable}
      message={toast.message}
      handleClose={handleCloseToast}
    />
  );
};

export default EventManager;
