import React from "react";

import Layout from "./Layout";
import ContactComponent from "../Components/ContactComponent";

const Contact = () => {
  return (
    <Layout>
      <section className="doorverse-contact mb-[60px] md:mb-[26px] md:mr-[30px]">
        <ContactComponent />
      </section>
    </Layout>
  );
};

export default Contact;
