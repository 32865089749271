import Select from "react-select";
import Radio from "@mui/material/Radio";
import DatePicker from "react-datepicker";
import { styled } from "@mui/material/styles";
import React, { useMemo, useState } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import { Box, Dialog, Typography } from "@mui/material";
import { styled as StyledComponent } from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";

import "react-datepicker/dist/react-datepicker.css";

import Api from "Helpers/Api";
import CircularLoader from "./Loader";
import API_PATH from "Helpers/UrlPaths";
import { ReactComponent as VerifyImage } from "Assets/Images/verify.svg";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
  radio: {
    "&$checked": {
      color: "#FFAB2B",
    },
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#FFAB2B",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#FFAB2B",
  },
});

const DialogWrapper = StyledComponent(Dialog)`
  .root {
    width: 50%;
    border-radius: 20px;
    padding: 20px;
    max-width: 400px;

    @media (max-width: 960px) {
      width: 80%;
    }
    @media (max-width: 600px) {
      width: 90%;
    }
  }
`;

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const ContactComponent = () => {
  const [loading, setLoading] = useState(false);
  const [purposeToBuy, setPurposeToBuy] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [scheduledTime, setScheduledTime] = useState();
  const [openThankyouModel, setThankyouModelOpen] = useState(false);

  const API = useMemo(() => new Api(), []);

  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    phoneNumber: "",
    schedule: "Schedule a Call",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (
        formData.fullName.trim().length === 0 ||
        formData.phoneNumber.trim().length === 0
      ) {
        alert("Please Fill required fields");
        return;
      }

      setLoading(true);

      const payload = {
        ...formData,
        purposeToBuy: purposeToBuy?.value || "",
        scheduledTime: scheduledTime?.value || "",
        selectedDate: selectedDate ? new Date(selectedDate).getTime() : "",
      };

      await API.post(API_PATH.CONTACT, { data: payload });

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  // for select drop-downs

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F3F4F6",
      borderRadius: "48px",
      padding: "6px",
      borderColor: state.isFocused ? "#0000" : "#9CA3AF",
      "&:hover": {
        borderColor: "#9CA3AF",
      },
      boxShadow: state.isFocused ? "0 0 0 1px #0000" : provided.boxShadow,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFAB2B" : "#F3F4F6",
      "&:hover": {
        backgroundColor: "#FFAB2B",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#F3F4F6",
    }),
  };

  const scheduleList = [
    { value: "Self Use", label: "Self Use" },
    { value: "Investment", label: "Investment" },
  ];

  const visitTimeList = [
    { value: "Morning(9AM - 12PM)", label: "Morning(9AM - 12PM)" },
    { value: "Afternoon(12PM - 2PM)", label: "Afternoon(12PM - 2PM)" },
    { value: "Evening(4PM - 7PM)", label: "Evening(4PM - 7PM)" },
  ];

  // custom styles for date picker

  const CustomInput = ({ value, onClick }) => (
    <>
      <div className="custom-input w-full ">
        <input
          id="form-date"
          type="text"
          value={value}
          onClick={onClick}
          readOnly
          placeholder="Select Date"
          className="w-full relative"
        ></input>
        <label htmlFor="form-date">
          <img
            src={"/cal.png"}
            alt="Calendar"
            className="absolute top-[25%] right-[15px]"
          />
        </label>
      </div>
    </>
  );

  return (
    <React.Fragment>
      <CircularLoader open={loading} />

      <div className="flex flex-col">
        <div className="flex flex-col gap-4 mb-5">
          <h2 className="text-center">Get in Touch</h2>
          <div className="justify-center items-center text-sm md:text-base flex flex-col md:flex-row">
            Call us at:
            <span className="font-medium pl-1">+91 9941191411</span>
          </div>
        </div>
        <div className="contact-form">
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <div className="flex w-full gap-[10px] flex-wrap justify-between">
              <div className="flex flex-col gap-2 w-full md:w-[48%] mb-[30px]">
                <label htmlFor="">Full name*</label>
                <input
                  onChange={handleChange}
                  type="text"
                  placeholder="Full name"
                  name="fullName"
                />
              </div>
              <div className="flex flex-col gap-2 w-full md:w-[48%] mb-[30px]">
                <label htmlFor="">Email</label>
                <input
                  onChange={handleChange}
                  type="email"
                  placeholder="Email"
                  name="email"
                />
              </div>
              <div className="flex flex-col gap-2 w-full md:w-[48%] mb-[30px]">
                <label htmlFor="">Phone Number*</label>
                <input
                  onChange={handleChange}
                  type="number"
                  placeholder="Phone Number"
                  name="phoneNumber"
                />
              </div>
              <div className="flex flex-col gap-2 w-full md:w-[48%] mb-[30px]">
                <label htmlFor="">Your purpose to buy</label>
                <Select
                  styles={customStyles}
                  options={scheduleList}
                  onChange={setPurposeToBuy}
                  defaultValue={purposeToBuy}
                />
              </div>
              <div className="flex flex-col gap-2 w-full mb-[30px]">
                <label>Schedule your interaction</label>
                <div className="flex flex-col md:flex-row justify-between gap-6 md:gap-0">
                  <RadioGroup
                    defaultValue="Schedule a Call"
                    aria-labelledby="demo-customized-radios"
                    name="schedule"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Schedule a Call"
                      control={<BpRadio />}
                      label="Schedule a Call"
                    />
                    <FormControlLabel
                      value="Book a Visit"
                      control={<BpRadio />}
                      label="Book a Visit"
                    />
                  </RadioGroup>
                </div>
              </div>
              <div className="flex flex-col gap-2 w-full md:w-[48%] mb-[30px]">
                <label htmlFor="">Select date of your visit</label>

                <DatePicker
                  selected={selectedDate}
                  customInput={<CustomInput />}
                  onChange={(e) => setSelectedDate(e)}
                  popperPlacement="top-start"
                  showIcon
                  minDate={new Date()}
                />
              </div>
              <div className="flex flex-col gap-2 w-full md:w-[48%] mb-[30px]">
                <label htmlFor="">Select time of your visit</label>
                <Select
                  styles={customStyles}
                  options={visitTimeList}
                  onChange={setScheduledTime}
                  defaultValue={scheduledTime}
                />
              </div>
            </div>
            <div className="text-center">
              <button>Submit</button>
            </div>
            <div className="justify-center items-center text-sm md:text-base flex flex-col md:flex-row mt-5 break-all text-center">
              RERA: PR/GJ/AHMEDABAD/AHMEDABADCITY/AUDA/RAA12880/070224/100928
            </div>
            <div className="justify-center items-center text-sm md:text-base flex flex-col md:flex-row mt-1 mb-3">
              WWW.GUJRERA.GUJARAT.GOV.IN/
            </div>

            <div className="scrolling-text-container">
              <p className="scrolling-text">
                Architectural dimension may vary as per the site condition.
                Developers/Architect reserves all rights to change/alter scheme
                related measurements, designs, drawings and price per unit etc.
                This brochure is not a legal document. This is only for the easy
                presentation of the project.
              </p>
            </div>
          </form>
        </div>
      </div>

      {openThankyouModel && (
        <DialogWrapper
          open
          onClose={() => setThankyouModelOpen(false)}
          classes={{ paper: "root" }}
        >
          <Box className="flex justify-center my-2">
            <VerifyImage height="40px" width="40px" />
          </Box>
          <Typography className="text-center">
            Thank you for submitting your interest.
          </Typography>
        </DialogWrapper>
      )}
    </React.Fragment>
  );
};

export default ContactComponent;
