import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { Suspense, useRef, useState } from "react";
import { OrbitControls, Html } from "@react-three/drei";
import { useDispatch, useSelector } from "react-redux";

import Env from "./Env.jsx";
import BlockFModel from "./BlockFModel.jsx";
import CustomLoader from "./CustomLoader.jsx";
import Layout from "Pages/3dModel/Layout.jsx";
import { showHideBlockModel } from "Redux/General/ManageTag.js";
import { DEFAULT_ROTATE, ROTATE_DIFF } from "Helpers/Common.js";

const BLOCK_TAGS = [
  { name: "F1", position: [10, 6, 10] },
  { name: "F2", position: [5, 6, 4] },
  { name: "F3", position: [2, 6, 0] },
  { name: "F4", position: [-2, 6, -5] },
  { name: "F5", position: [-6, 6, -10] },
];

function BlockF() {
  const controls = useRef();
  const dispatch = useDispatch();
  const [rotateDegree, setRotationDegree] = useState(DEFAULT_ROTATE);

  const { isLabelShow } = useSelector((state) => state.ManageTag);

  return (
    <>
      <Layout rotateDegree={rotateDegree}>
        <Suspense fallback={<CustomLoader />}>
          <Canvas
            gl={{ outputColorSpace: THREE.SRGBColorSpace }}
            camera={{ fov: 45, near: 0.5, far: 200, position: [40, -15, -5] }}
          >
            <OrbitControls
              enableDamping={false}
              makeDefault
              maxDistance={50}
              minDistance={25}
              maxPolarAngle={Math.PI * 0.45}
              ref={controls}
              onChange={() => {
                const radians = controls.current.getAzimuthalAngle();
                setRotationDegree(radians * (180 / Math.PI) - ROTATE_DIFF);
              }}
            />
            <Env />
            <BlockFModel />

            {isLabelShow ? (
              BLOCK_TAGS.length > 0 &&
              BLOCK_TAGS.map((item) => (
                <Html
                  position={item.position}
                  key={item.name}
                  wrapperClass="Block_label"
                >
                  <span onClick={() => dispatch(showHideBlockModel(item.name))}>
                    {item.name}
                  </span>
                </Html>
              ))
            ) : (
              <></>
            )}

            {/* <Html position={[-6, 6, -10]} wrapperClass="Block_label">
              F5
            </Html>
            <Html position={[-2, 6, -5]} wrapperClass="Block_label">
              F4
            </Html>
            <Html position={[2, 6, 0]} wrapperClass="Block_label">
              F3
            </Html>
            <Html position={[5, 6, 4]} wrapperClass="Block_label">
              F2
            </Html>
            <Html position={[10, 6, 10]} wrapperClass="Block_label">
              F1
            </Html> */}
          </Canvas>
        </Suspense>
      </Layout>
    </>
  );
}

export default BlockF;
