/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Sidenav, Modal, Ripple, Dropdown, initTE } from "tw-elements";

import arrow from "../../Assets/Images/arrow.svg";
import Close from "../../Assets/Images/close.svg";
import ContactComponent from "../ContactComponent";
import { EXTERIOR_MENU_LIST } from "Helpers/Common";
import mobilemenu from "../../Assets/Images/menu.svg";
import rightarrow from "../../Assets/Images/nextarrow.svg";
import Logo from "../../Assets/Images/amrutinfracon-logo.png";

const Header = () => {
  const location = useLocation();
  const envName = process.env.REACT_APP_ENVIRONMENT || "dev";

  const menuRef = useRef(null);
  const exteriorMenuRef = useRef(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isExteriorMenuVisible, setExteriorMenuVisible] = useState(false);

  useEffect(() => {
    initTE({ Modal, Ripple, Sidenav, Dropdown });

    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target))
        setMenuVisible(false);

      if (
        exteriorMenuRef.current &&
        !exteriorMenuRef.current.contains(event.target)
      ) {
        setExteriorMenuVisible(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const toggleExteriorMenu = () => {
    setExteriorMenuVisible(!isExteriorMenuVisible);
  };

  return (
    <>
      <header className="doorverse-header bg-[#F3F4F6]">
        <nav className="hidden lg:flex px-[70px] py-[15px] justify-between items-center">
          <div>
            <img src={Logo} alt="Logo" />
          </div>
          <div className="flex">
            <ul className="hidden lg:flex items-center">
              <li className="">
                <Link to={`/`}>Home</Link>
              </li>
              <li
                className={
                  location.pathname.includes("connectivity") ? "active" : ""
                }
              >
                <Link to={`/connectivity`}>Connectivity</Link>
              </li>

              <li
                className={
                  location.pathname.includes("specifications") ? "active" : ""
                }
              >
                <Link to={`/specifications`}>Specifications</Link>
              </li>
              <li
                className={
                  location.pathname.includes("amenities-3d") ? "active" : ""
                }
              >
                <Link to={`/3d-model/amenities-3d`}>Amenities 3D</Link>
              </li>
              <li
                className={`${
                  location.pathname.includes("exterior") ? "active" : ""
                } relative`}
                ref={exteriorMenuRef}
              >
                <button
                  onClick={toggleExteriorMenu}
                  className={
                    location.pathname.includes("exterior") ? "font-bold" : ""
                  }
                >
                  Exterior View
                </button>
                {isExteriorMenuVisible && (
                  <ul
                    id="doorverse-nested-menu"
                    className="doorverse-nested-menu"
                    style={{
                      height: "80vh",
                      overflowY: "auto",
                      zIndex: 99999999,
                    }}
                  >
                    <ul className="w-full">
                      {EXTERIOR_MENU_LIST.length &&
                        EXTERIOR_MENU_LIST.map((item) => (
                          <li className="mx-3" key={item.text}>
                            <Link to={item.path}>{item.text}</Link>
                          </li>
                        ))}
                    </ul>
                  </ul>
                )}
              </li>

              {envName !== "prod" && (
                <li
                  className={`${
                    location.pathname.includes("interior") ? "active" : ""
                  } relative`}
                  ref={menuRef}
                >
                  <button
                    onClick={toggleMenu}
                    className={
                      location.pathname.includes("interior") ? "font-bold" : ""
                    }
                  >
                    Interior View
                  </button>
                  {isMenuVisible && (
                    <ul
                      id="doorverse-nested-menu"
                      className="doorverse-nested-menu"
                    >
                      <ul className="w-full">
                        <li>
                          <Link to={"/3d-model/interior/shiv-agan"}>
                            Shiv Angan
                          </Link>
                        </li>
                        <li>
                          <Link to={"/3d-model/interior/prem-agan"}>
                            Prem Angan
                          </Link>
                        </li>
                      </ul>
                    </ul>
                  )}
                </li>
              )}

              <li>
                <button
                  type="button"
                  className="primary_button"
                  data-te-toggle="modal"
                  data-te-target="#ContactModal"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  Contact us
                </button>
              </li>
            </ul>
          </div>
        </nav>
        <nav className="flex lg:hidden px-[24px] py-[18px] justify-between items-center">
          <button
            className="flex lg:hidden items-center w-auto"
            data-te-sidenav-toggle-ref
            data-te-target="#sidenav-menu"
            aria-controls="#sidenav-menu"
            aria-haspopup="true"
          >
            <img src={mobilemenu} alt="" />
          </button>
          <div className="w-full">
            <img src={Logo} alt="Logo" className="mx-auto" />
          </div>
        </nav>
      </header>
      {/* <!--Contact Us centered modal--> */}
      <div
        data-te-modal-init
        className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="ContactModal"
        tabIndex="-1"
        aria-labelledby="ContactModalTitle"
        aria-modal="true"
        role="dialog"
      >
        <div
          data-te-modal-dialog-ref
          className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[722px]"
        >
          <div className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            {/* <!--Modal body--> */}
            <div className="relative p-10 doorverse-contact">
              <ContactComponent />
            </div>
          </div>
        </div>
      </div>
      {/* <!--Contact Us centered modal--> */}
      {/* SideNav Menu */}
      <nav
        id="sidenav-menu"
        className="absolute left-0 top-0 z-[1035] h-full w-full -translate-x-full overflow-hidden bg-white shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-zinc-800 doorverse-mobile-nav"
        data-te-sidenav-init
        data-te-sidenav-hidden="true"
        data-te-sidenav-position="absolute"
      >
        <div
          className="flex flex-row px-6 justify-between bg-[#F3F4F6]"
          style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
        >
          <div style={{ display: "contents" }}>
            <img src={Logo} alt="Logo" className="mx-auto" />
          </div>
          <div style={{ display: "grid" }}>
            <button
              type="button"
              className="box-content rounded-none border-none opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              data-te-sidenav-toggle-ref
              data-te-target="#sidenav-menu"
            >
              <img
                src={Close}
                className="rotate-180 w-6 h-6"
                alt="Close Button"
              />
            </button>
          </div>
        </div>
        <div className="flex">
          <ul className="flex flex-col w-full h-[calc(100vh-7rem)] pt-6 pb-4 px-6 gap-6 justify-between">
            <div className="flex flex-col gap-6">
              <li className="">
                <Link to={`/`}>Home</Link>
              </li>

              <li
                className={
                  location.pathname.includes("connectivity") ? "active" : ""
                }
              >
                <Link to={`/connectivity`}>Connectivity</Link>
              </li>

              <li
                className={
                  location.pathname.includes("specifications") ? "active" : ""
                }
              >
                <Link to={`/specifications`}>Specifications</Link>
              </li>

              <li
                className={
                  location.pathname.includes("amenities-3d") ? "active" : ""
                }
              >
                <Link to={`/3d-model/amenities-3d`}>Amenities 3D</Link>
              </li>
              <li
                data-te-sidenav-toggle-ref
                data-te-target="#sidenav-exterior-menu"
                aria-controls="#sidenav-exterior-menu"
                className={`flex justify-between ${
                  location.pathname.includes("exterior") ? "active" : ""
                }`}
              >
                Exterior View
                <img src={arrow} alt="" />
              </li>
              {envName !== "prod" && (
                <li
                  data-te-sidenav-toggle-ref
                  data-te-target="#sidenav-inner-menu"
                  aria-controls="#sidenav-inner-menu"
                  className={`flex justify-between ${
                    location.pathname.includes("interior") ? "active" : ""
                  }`}
                >
                  Interior View
                  <img src={arrow} alt="" />
                </li>
              )}

              <li>
                <button
                  type="button"
                  className="primary_button"
                  data-te-toggle="modal"
                  data-te-target="#ContactModal"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  Contact Us
                </button>
              </li>
              <nav
                id="sidenav-exterior-menu"
                className="absolute left-0 top-0 z-[1035] h-full w-full -translate-x-full overflow-hidden bg-white shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-zinc-800"
                data-te-sidenav-init
                data-te-sidenav-hidden="true"
                data-te-sidenav-position="absolute"
              >
                <div className="flex flex-row pt-12 pb-4 px-6 justify-between bg-[#F3F4F6]">
                  <div className="flex w-full gap-4 items-center">
                    <button
                      data-te-sidenav-toggle-ref
                      data-te-target="#sidenav-exterior-menu"
                    >
                      <img
                        src={rightarrow}
                        alt=""
                        className="rotate-180 w-6 h-6"
                      />
                    </button>
                    <h2>Exterior View</h2>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="box-content rounded-none border-none opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                      data-te-sidenav-toggle-ref
                      data-te-target="#sidenav-exterior-menu"
                    >
                      <img
                        src={Close}
                        className="rotate-180 w-6 h-6"
                        alt="Close Button"
                      />
                    </button>
                  </div>
                </div>

                <div className="flex mobile-menu">
                  <ul className="flex flex-col w-full h-[calc(100vh-7rem)] pt-6 pb-4 px-6 gap-6 justify-between">
                    <div className="flex flex-col gap-6">
                      <li>
                        <Link to={"/3d-model/exterior/layout"}>Layout</Link>
                      </li>
                      <li>
                        <Link to={"/3d-model/exterior/block-a"}>Block A</Link>
                      </li>
                      <li>
                        <Link to={"/3d-model/exterior/block-b"}>Block B</Link>
                      </li>
                      <li>
                        <Link to={"/3d-model/exterior/block-c"}>Block C</Link>
                      </li>
                      <li>
                        <Link to={"/3d-model/exterior/block-d"}>Block D</Link>
                      </li>
                      <li>
                        <Link to={"/3d-model/exterior/block-d"}>Block D</Link>
                      </li>
                      <li>
                        <Link to={"/3d-model/exterior/block-f"}>Block F</Link>
                      </li>
                      <li>
                        <Link to={"/3d-model/exterior/block-g"}>Block G</Link>
                      </li>
                      <li>
                        <Link to={"/3d-model/exterior/block-h"}>Block H</Link>
                      </li>
                      <li>
                        <Link to={"/3d-model/exterior/block-i"}>Block I</Link>
                      </li>
                      <li>
                        <Link to={"/3d-model/exterior/block-j"}>Block J</Link>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="primary_button"
                          data-te-toggle="modal"
                          data-te-target="#ContactModal"
                          data-te-ripple-init
                          data-te-ripple-color="light"
                        >
                          Contact us
                        </button>
                      </li>
                    </div>
                    <li></li>
                  </ul>
                </div>
              </nav>

              {envName !== "prod" && (
                <>
                  {/* SideNav Inner Menu */}
                  <nav
                    id="sidenav-inner-menu"
                    className="absolute left-0 top-0 z-[1035] h-full w-full -translate-x-full overflow-hidden bg-white shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-zinc-800"
                    data-te-sidenav-init
                    data-te-sidenav-hidden="true"
                    data-te-sidenav-position="absolute"
                  >
                    <div className="flex flex-row pt-12 pb-4 px-6 justify-between bg-[#F3F4F6]">
                      <div className="flex w-full gap-4 items-center">
                        <button
                          data-te-sidenav-toggle-ref
                          data-te-target="#sidenav-inner-menu"
                        >
                          <img
                            src={rightarrow}
                            alt=""
                            className="rotate-180 w-6 h-6"
                          />
                        </button>
                        <h2>Interior View</h2>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="box-content rounded-none border-none opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                          data-te-sidenav-toggle-ref
                          data-te-target="#sidenav-inner-menu"
                        >
                          <img
                            src={Close}
                            className="rotate-180 w-6 h-6"
                            alt="Close Button"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="flex">
                      <ul className="flex flex-col w-full h-[calc(100vh-7rem)] pt-6 pb-4 px-6 gap-6 justify-between">
                        <div className="flex flex-col gap-6">
                          <li>
                            <Link to={"/3d-model/interior/shiv-agan"}>
                              Shiv Angan
                            </Link>
                          </li>
                          <li>
                            <Link to={"/3d-model/interior/prem-agan"}>
                              Prem Angan
                            </Link>
                          </li>
                          <li>
                            <button
                              type="button"
                              className="primary_button"
                              data-te-toggle="modal"
                              data-te-target="#ContactModal"
                              data-te-ripple-init
                              data-te-ripple-color="light"
                            >
                              Contact us
                            </button>
                          </li>
                        </div>
                        <li></li>
                      </ul>
                    </div>
                  </nav>
                  {/* SideNav Inner Menu */}
                </>
              )}
            </div>
          </ul>
        </div>
      </nav>
      {/* SideNav Menu */}
    </>
  );
};

export default Header;
