import Layout from "Pages/3dModel/Layout";

const ShivAngan = () => {
  return (
    <Layout>
      <div style={{ height: "100vh" }}>
        <iframe
          src={
            "https://65eaa10206603d3bc27eda81--silver-pithivier-58732b.netlify.app/"
          }
          width="100%"
          height="100%"
          title="load-one"
        />
      </div>
    </Layout>
  );
};

export default ShivAngan;
