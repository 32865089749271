import * as THREE from "three";
import { useDispatch, useSelector } from "react-redux";
import { Canvas } from "@react-three/fiber";
import { Suspense, useRef, useState } from "react";
import { OrbitControls, Html } from "@react-three/drei";

import Env from "./Env.jsx";
import Layout from "Pages/3dModel/Layout.jsx";
import BlockBCDModel from "./BlockBCDModel.jsx";
import CustomLoader from "./CustomLoader.jsx";

import { showHideBlockModel } from "Redux/General/ManageTag.js";
import { DEFAULT_ROTATE, ROTATE_DIFF } from "Helpers/Common.js";

const BLOCK_TAGS = [
  { name: "C1", position: [-2, 7, -16] },
  { name: "C2", position: [0, 5, -12] },
  { name: "C3", position: [5, 5, -6] },
  { name: "C4", position: [8, 5, -1] },
  { name: "C5", position: [13, 5, 7] },
  { name: "C6", position: [2, 5, 15] },
  { name: "C7", position: [-4, 5, 7] },
  { name: "C8", position: [-7, 5, 3] },
  { name: "C9", position: [-12, 5, -3] },
  { name: "C10", position: [-16, 7, -7] },
];

function BlockC() {
  const controls = useRef();
  const dispatch = useDispatch();
  const [rotateDegree, setRotationDegree] = useState(DEFAULT_ROTATE);
  const { isLabelShow } = useSelector((state) => state.ManageTag);

  return (
    <>
      <Layout rotateDegree={rotateDegree}>
        <Suspense fallback={<CustomLoader />}>
          <Canvas
            gl={{ outputColorSpace: THREE.SRGBColorSpace }}
            camera={{ fov: 45, near: 0.5, far: 200, position: [40, -15, -5] }}
          >
            <OrbitControls
              enableDamping={false}
              makeDefault
              maxDistance={50}
              minDistance={25}
              maxPolarAngle={Math.PI * 0.45}
              ref={controls}
              onChange={() => {
                const radians = controls.current.getAzimuthalAngle();
                setRotationDegree(radians * (180 / Math.PI) - ROTATE_DIFF);
              }}
            />
            <Env />
            <BlockBCDModel />

            {isLabelShow ? (
              BLOCK_TAGS.length > 0 &&
              BLOCK_TAGS.map((item) => (
                <Html
                  position={item.position}
                  key={item.name}
                  wrapperClass="Block_label"
                >
                  <span onClick={() => dispatch(showHideBlockModel(item.name))}>
                    {item.name}
                  </span>
                </Html>
              ))
            ) : (
              <></>
            )}
            {/* <Html position={[-2, 7, -16]} wrapperClass="Block_label">
                  C1
                </Html>
                <Html position={[0, 5, -12]} wrapperClass="Block_label">
                  C2
                </Html>
                <Html position={[5, 5, -6]} wrapperClass="Block_label">
                  C3
                </Html>
                <Html position={[8, 5, -1]} wrapperClass="Block_label">
                  C4
                </Html>
                <Html position={[13, 5, 7]} wrapperClass="Block_label">
                  C5
                </Html>
                <Html position={[2, 5, 15]} wrapperClass="Block_label">
                  C6
                </Html>
                <Html position={[-4, 5, 7]} wrapperClass="Block_label">
                  C7
                </Html>
                <Html position={[-7, 5, 3]} wrapperClass="Block_label">
                  C8
                </Html>
                <Html position={[-12, 5, -3]} wrapperClass="Block_label">
                  C9
                </Html>
                <Html position={[-16, 7, -7]} wrapperClass="Block_label">
                  C10
                </Html> */}
          </Canvas>
        </Suspense>
      </Layout>
    </>
  );
}

export default BlockC;
