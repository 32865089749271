import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { Suspense, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrbitControls, Html } from "@react-three/drei";

import Env from "./Env.jsx";
import BlockAModel from "./BlockAModel.jsx";
import Layout from "Pages/3dModel/Layout.jsx";
import CustomLoader from "./CustomLoader.jsx";
import { showHideBlockModel } from "Redux/General/ManageTag.js";
import { DEFAULT_ROTATE, ROTATE_DIFF } from "Helpers/Common.js";

const BLOCK_TAGS = [
  { name: "A1", position: [-3, 9, -18] },
  { name: "A2", position: [1, 9, -13] },
  { name: "A3", position: [4, 9, -9] },
  { name: "A4", position: [6, 9, -6] },
  { name: "A5", position: [8, 9, -3] },
  { name: "A6", position: [10, 9, 0] },
  { name: "A7", position: [14, 9, 4] },
  { name: "A8", position: [-1, 9, 14] },
  { name: "A9", position: [-3, 9, 11] },
  { name: "A10", position: [-5, 9, 8] },
  { name: "A11", position: [-8, 9, 5] },
  { name: "A12", position: [-10, 9, 2] },
  { name: "A12a", position: [-12, 9, -2] },
  { name: "A14", position: [-16, 9, -7] },
];

function BlockA() {
  const controls = useRef();
  const dispatch = useDispatch();
  const [rotateDegree, setRotationDegree] = useState(DEFAULT_ROTATE);
  const { isLabelShow } = useSelector((state) => state.ManageTag);

  return (
    <>
      <Layout rotateDegree={rotateDegree}>
        <Suspense fallback={<CustomLoader />}>
          <Canvas
            gl={{ outputColorSpace: THREE.SRGBColorSpace }}
            camera={{ fov: 45, near: 0.5, far: 200, position: [40, -15, -5] }}
          >
            <OrbitControls
              enableDamping={false}
              makeDefault
              maxDistance={50}
              minDistance={25}
              maxPolarAngle={Math.PI * 0.45}
              ref={controls}
              onChange={() => {
                const radians = controls.current.getAzimuthalAngle();
                setRotationDegree(radians * (180 / Math.PI) - ROTATE_DIFF);
              }}
            />
            <Env />
            <BlockAModel />

            {isLabelShow ? (
              BLOCK_TAGS.length > 0 &&
              BLOCK_TAGS.map((item) => (
                <Html
                  position={item.position}
                  key={item.name}
                  wrapperClass="Block_label"
                >
                  <span onClick={() => dispatch(showHideBlockModel(item.name))}>
                    {item.name}
                  </span>
                </Html>
              ))
            ) : (
              <></>
            )}
          </Canvas>
        </Suspense>
      </Layout>
    </>
  );
}

export default BlockA;
