import { createSlice } from "@reduxjs/toolkit";

const initState = {
  isLoading: false,
};

export const slice = createSlice({
  name: "Loading",
  initialState: { ...initState },
  reducers: {
    manageLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { manageLoading } = slice.actions;

export default slice.reducer;
