import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import Api from "Helpers/Api";
import API_PATH from "Helpers/UrlPaths";
import CircularLoader from "Components/Loader";
import { manageSession, manageUser } from "Redux/General/Auth";
import { mobileDevicePattern, SESSION_ID, USER_ID } from "Helpers/Common";

const OTP_SECONDS = 30;
const NUM_OF_INPUTS = 4;

const VerifyOtp = ({
  otp,
  setOtp,
  formValues,
  phoneNumber,
  setModalState,
  requiredDetails,
  ...props
}) => {
  const dispatch = useDispatch();

  const [seconds, setSeconds] = useState(OTP_SECONDS);

  const isMobileDevice = useMemo(
    () => mobileDevicePattern.test(navigator.userAgent),
    []
  );

  const API = useMemo(() => new Api(), []);
  const [loading, setLoading] = useState(false);
  const { geoLocation } = useSelector((state) => state.Auth);

  const verifyOtp = async () => {
    try {
      setLoading(true);
      let { data } = await API.post(API_PATH.VERIFY_OTP, {
        data: {
          phoneNumber,
          geoLocation,
          currPage: "HOME",
          countryCode: "+91",
          otp: parseInt(otp),
          viewType: isMobileDevice ? "Mobile" : "Web",
        },
      });

      if (data) {
        localStorage.setItem(USER_ID, data.userId);
        localStorage.setItem(SESSION_ID, data.sessionId);
        dispatch(manageSession(data.sessionId));
        dispatch(manageUser(data.userId));

        setLoading(false);
        setModalState(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const resendOtp = async () => {
    if (!formValues?.countryCode || !formValues.phone) return;
    setLoading(true);
    let response = await API.post(API_PATH.RE_SEND_OTP, {
      data: {
        countryCode: "+91",
        phoneNumber: formValues.phone,
      },
    });
    if (response) setSeconds(OTP_SECONDS);
    setLoading(false);
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <React.Fragment>
      <Box className="mb-5">OTP is sent to your mobile number.</Box>
      <CircularLoader open={loading} />
      <Box className="w-100 m-auto">
        <OtpInput
          value={otp}
          onChange={setOtp}
          inputStyle={{
            width: "40px",
            height: "40px",
            margin: "0 5px 0 0",
            border: "1px solid",
            borderRadius: "4px",
          }}
          numInputs={NUM_OF_INPUTS}
          inputType="tel"
          renderSeparator={<span> </span>}
          shouldAutoFocus
          renderInput={(props) => <input {...props} />}
        />
      </Box>
      <Box className="flex mt-5 ml-auto">
        <Button
          className={`sendOtpBtn ${
            otp?.length !== NUM_OF_INPUTS && "disabled"
          }`}
          disabled={otp?.length !== NUM_OF_INPUTS}
          onClick={() => verifyOtp()}
        >
          Verify
        </Button>
      </Box>
      <Box className="mt-2 flex flex-col">
        <Box className="flex justify-center w-max mx-auto">
          <Typography
            className={`pl-2 ${
              !seconds && "hover:text-violet-600 hover:underline cursor-pointer"
            }`}
            onClick={() => !seconds && resendOtp()}
          >
            Re-send Otp
          </Typography>
          {!!seconds && (
            <Typography className="pl-1">in {seconds} seconds</Typography>
          )}
        </Box>
        <Typography
          className="text-center cursor-pointer hover:text-violet-600 hover:underline"
          onClick={() => props?.setIsOtpSentStatus(false)}
        >
          Update contact number?
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default VerifyOtp;
