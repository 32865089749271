import Layout from "Pages/3dModel/Layout";

const PremAngan = () => {
  return (
    <Layout>
      <div style={{ height: "100vh" }}>
        <iframe
          src={
            "https://65eaa0c6f899fb3a79268a98--leafy-centaur-e104ef.netlify.app/"
          }
          width="100%"
          height="100%"
          title="second-one"
        />
      </div>
    </Layout>
  );
};

export default PremAngan;
