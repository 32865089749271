import React from "react";
import Layout from "./Layout";
import ConnectivityComponent from "../Components/ConnectivityComponent";
const Connectivity = () => {
  return (
    <Layout>
      <section className="md:h-[655px] md:mb-[56px] md:mr-[40px] md:overflow-y-auto">
        <ConnectivityComponent />
      </section>
    </Layout>
  );
};

export default Connectivity;
