import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./Assets/Fonts/Montserrat-Bold.ttf";
import "./Assets/Fonts/Montserrat-Light.ttf";
import "./Assets/Fonts/Montserrat-Medium.ttf";
import "./Assets/Fonts/Montserrat-Regular.ttf";

import "./index.css";

import store from "Redux/Store";

import reportWebVitals from "./reportWebVitals";
import EventManager from "Components/EventManager";

import App from "App";

const basename = process.env.REACT_APP_BASE_HREF || "/";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter basename={basename}>
    <Provider store={store}>
      <EventManager />
      <App />
    </Provider>
  </BrowserRouter>
);

reportWebVitals();
