import React from "react";
import Doorverse from "../../Assets/Images/DOORVERSE.png";
import { useLocation } from "react-router-dom";
const Footer = () => {
  const location = useLocation();
  const isModelPage =
    location.pathname === "/3d-model/exterior/layout" ||
    location.pathname === "/3d-model/interior/floor" ||
    location.pathname === "/3d-model/interior/interior";

  return (
    <footer
      className={`doorverse-footer bg-[#232735] ${
        isModelPage ? "doorverse_3d_section_footer" : ""
      }`}
    >
      <div className="flex flex-col md:flex-row gap-4 px-[30px] md:px-[70px] py-[15px] md:py-[30px] justify-between items-center">
        <div>
          <img src={Doorverse} alt="Doorverse" />
        </div>
        <div className="mid_div">
          <span className=" text-sm md:text-base">
            Contact for 3D model: +91-9898764963
          </span>
        </div>
        <div>
          <span className=" text-sm md:text-base">
            © 2023 Doorverse. All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
