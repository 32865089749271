import { createSlice } from "@reduxjs/toolkit";

import { BLOCK_AANGAN_2D_PLANS, TAG_STATE } from "Helpers/Common";

const initState = {
  ...TAG_STATE,
  unitName: "",
  floor2DPlan: "",
  showCompass: false,
  isLabelShow: false,
};

export const slice = createSlice({
  name: "ManageTag",
  initialState: { ...initState },
  reducers: {
    showHideTag: (state, action) => {
      return { ...state, ...action.payload };
    },
    showHideCompass: (state, action) => {
      state.showCompass = action.payload;
    },
    showHideBlockModel: (state, action) => {
      state.unitName = action.payload;
      state.floor2DPlan = BLOCK_AANGAN_2D_PLANS[action.payload];
    },
  },
});

export const { showHideTag, showHideCompass, showHideBlockModel } =
  slice.actions;

export default slice.reducer;
