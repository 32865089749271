/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";

import whatsapp from "../../Assets/Images/whatsapp.svg";
import facebook from "../../Assets/Images/facebook.svg";
import gmail from "../../Assets/Images/gmail.svg";

const ShareInner = ({ copyText }) => {
  const [isCopied, setIsCopied] = useState(false);

  const openLink = (url) => {
    window.open(url, "_blank");
  };

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <>
      {/* <!--Modal body--> */}
      <div className="relative py-10 px-[50px] md:px-[0px] w-full md:w-[360px]  flex justify-between">
        <div className=" p-[10px] rounded-full bg-[#F3F4F6] w-[60px] h-[60px]">
          <WhatsappShareButton url={copyText}>
            <img src={whatsapp} alt="" />
          </WhatsappShareButton>
        </div>
        <div className=" p-[10px] rounded-full bg-[#F3F4F6] w-[60px] h-[60px]">
          <FacebookShareButton
            url={copyText}
            quote={"Dummy text!"}
            hashtag="#muo"
          >
            <img
              src={facebook}
              alt=""
              onClick={() =>
                openLink("https://www.facebook.com/amrutinfraconnew")
              }
            />
          </FacebookShareButton>
        </div>
        <div className=" p-[10px] rounded-full bg-[#F3F4F6] w-[60px] h-[60px]">
          <EmailShareButton url={copyText} subject="Hello" body="Hello">
            <img src={gmail} alt="" />
          </EmailShareButton>
        </div>
        {/* <div className=" p-[10px] rounded-full bg-[#F3F4F6] w-[60px] h-[60px]">
          <a href={`https://instagram.com/direct_share?url=${copyText}`}>
            <img src={instagram} alt="" />
          </a>
        </div> */}
      </div>
      <div className="relative w-full p-10 rounded-b-[30px] bg-[#E5E7EB] flex justify-between doorverse-copy-link">
        <div className="flex w-full items-center">
          <span>{copyText}</span>
          <button onClick={handleCopyClick}>
            {isCopied ? "Copied!" : "Copy"}
          </button>
        </div>
      </div>
      {/* <!--Modal body--> */}
    </>
  );
};

export default ShareInner;
