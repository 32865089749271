import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { Suspense, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrbitControls, Html } from "@react-three/drei";

import Env from "./Env.jsx";
import Layout from "Pages/3dModel/Layout.jsx";
import CustomLoader from "./CustomLoader.jsx";
import OverviewModel from "./OverviewModel.jsx";
import FakeGlowMaterial from "./FakeGlowMaterial.jsx";
import { showHideBlockModel } from "Redux/General/ManageTag.js";
import {
  Prem_Angan,
  Shiv_Angan,
  Surya_Angan,
  Tulsi_Angan,
  ROTATE_DIFF,
  Swapna_Angan,
  Prakruti_Angan,
  DEFAULT_ROTATE,
} from "Helpers/Common.js";

function ExteriorLayout() {
  const controls = useRef();
  const dispatch = useDispatch();
  const [rotateDegree, setRotationDegree] = useState(DEFAULT_ROTATE);

  const {
    shivAangan,
    premAangan,
    tulsiAangan,
    suryaAangan,
    swapnaAangan,
    prakrutiAangan,
  } = useSelector((state) => state.ManageTag);

  const handleModal = ({ name }) => {
    dispatch(showHideBlockModel(name));
  };

  return (
    <>
      <Layout rotateDegree={rotateDegree}>
        <Suspense fallback={<CustomLoader />}>
          <Canvas
            gl={{ outputColorSpace: THREE.SRGBColorSpace }}
            camera={{ fov: 45, near: 1, far: 700, position: [100, 50, 140] }}
          >
            <OrbitControls
              enableDamping={false}
              makeDefault
              ref={controls}
              minDistance={35}
              maxDistance={200}
              maxPolarAngle={Math.PI * 0.45}
              onChange={() => {
                const radians = controls.current.getAzimuthalAngle();
                setRotationDegree(radians * (180 / Math.PI) - ROTATE_DIFF);
              }}
            />

            {/* SUN */}
            <mesh scale={10} position={[-354, 22, -80]}>
              <sphereGeometry />
              <FakeGlowMaterial glowColor="orange" />
            </mesh>

            <Env />
            <OverviewModel />

            {tulsiAangan &&
              Tulsi_Angan.length > 0 &&
              Tulsi_Angan.map((item) => (
                <Html
                  position={item.position}
                  key={item.name}
                  wrapperClass="three-bhk-label cursor"
                >
                  <span onClick={() => handleModal(item)}>{item.name}</span>
                </Html>
              ))}

            {suryaAangan &&
              Surya_Angan.length > 0 &&
              Surya_Angan.map((item) => (
                <Html
                  position={item.position}
                  key={item.name}
                  wrapperClass="four-bhk-label cursor"
                >
                  <span onClick={() => handleModal(item)}>{item.name}</span>
                </Html>
              ))}

            {shivAangan &&
              Shiv_Angan.length > 0 &&
              Shiv_Angan.map((item) => (
                <Html
                  position={item.position}
                  key={item.name}
                  wrapperClass="three-bhk-label cursor"
                >
                  <span onClick={() => handleModal(item)}>{item.name}</span>
                </Html>
              ))}

            {swapnaAangan &&
              Swapna_Angan.length > 0 &&
              Swapna_Angan.map((item) => (
                <Html
                  position={item.position}
                  key={item.name}
                  wrapperClass="four-bhk-label cursor"
                >
                  <span onClick={() => handleModal(item)}>{item.name}</span>
                </Html>
              ))}

            {premAangan &&
              Prem_Angan.length > 0 &&
              Prem_Angan.map((item) => (
                <Html
                  position={item.position}
                  key={item.name}
                  wrapperClass="four-bhk-label cursor"
                >
                  <span onClick={() => handleModal(item)}>{item.name}</span>
                </Html>
              ))}

            {prakrutiAangan &&
              Prakruti_Angan.length > 0 &&
              Prakruti_Angan.map((item) => (
                <Html
                  position={item.position}
                  key={item.name}
                  wrapperClass="four-bhk-label cursor"
                >
                  <span onClick={() => handleModal(item)}>{item.name}</span>
                </Html>
              ))}
          </Canvas>
        </Suspense>
      </Layout>
    </>
  );
}

export default ExteriorLayout;
