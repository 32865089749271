import { createSlice } from "@reduxjs/toolkit";

import { GEO_LOCATION, SESSION_ID, USER_ID } from "Helpers/Common";

const geoLocation = localStorage.getItem(GEO_LOCATION) || null;

const initState = {
  [USER_ID]: localStorage.getItem(USER_ID) || "",
  [SESSION_ID]: localStorage.getItem(SESSION_ID) || "",
  [GEO_LOCATION]: geoLocation ? JSON.parse(geoLocation) : null,
};

export const slice = createSlice({
  name: "auth",
  initialState: { ...initState },
  reducers: {
    manageUser: (state, action) => {
      state[USER_ID] = action.payload;
    },
    manageSession: (state, action) => {
      state[SESSION_ID] = action.payload;
    },
    manageGeoLocation: (state, action) => {
      state[GEO_LOCATION] = action.payload;
      localStorage.setItem(GEO_LOCATION, JSON.stringify(action.payload));
    },
  },
});

export const { manageUser, manageSession, manageGeoLocation } = slice.actions;

export default slice.reducer;
