import Layout from "./Layout";
import React, { useCallback, useEffect, useState } from "react";

import { USER_ID } from "Helpers/Common";
import OverviewComp from "../Components/OverviewComp";
import ContactVerification from "Components/ContactVerification/ContactVerification";

const Overview = () => {
  const [getContact, setContact] = useState(false);

  const openContactInfo = useCallback(() => {
    setTimeout(() => {
      let isDataStored = localStorage.getItem(USER_ID) ? true : false;
      setContact(!isDataStored);
    }, 2000);
  }, []);

  useEffect(() => {
    openContactInfo();
  }, [openContactInfo]);

  return (
    <Layout>
      <section className="mb-[286px]">
        {getContact && <ContactVerification open={getContact} />}
        <OverviewComp />
      </section>
    </Layout>
  );
};

export default Overview;
