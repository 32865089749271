import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { Suspense, useRef, useState } from "react";
import { OrbitControls, Html } from "@react-three/drei";

import Env from "./Env.jsx";
import BlockEModel from "./BlockEModel.jsx";
import CustomLoader from "./CustomLoader.jsx";
import Layout from "Pages/3dModel/Layout.jsx";
import { useDispatch, useSelector } from "react-redux";
import { showHideBlockModel } from "Redux/General/ManageTag.js";
import { DEFAULT_ROTATE, ROTATE_DIFF } from "Helpers/Common.js";

const BLOCK_TAGS = [
  { name: "E1", position: [-6, 5, -10] },
  { name: "E2", position: [-2, 5, -5] },
  { name: "E3", position: [2, 5, 0] },
  { name: "E4", position: [5, 5, 4] },
  { name: "E5", position: [10, 5, 10] },
];

function BlockE() {
  const controls = useRef();
  const dispatch = useDispatch();
  const [rotateDegree, setRotationDegree] = useState(DEFAULT_ROTATE);

  const { isLabelShow } = useSelector((state) => state.ManageTag);

  return (
    <>
      <Layout rotateDegree={rotateDegree}>
        <Suspense fallback={<CustomLoader />}>
          <Canvas
            gl={{ outputColorSpace: THREE.SRGBColorSpace }}
            camera={{ fov: 45, near: 0.5, far: 200, position: [40, -15, -5] }}
          >
            <OrbitControls
              enableDamping={false}
              makeDefault
              maxDistance={50}
              minDistance={25}
              maxPolarAngle={Math.PI * 0.45}
              ref={controls}
              onChange={() => {
                const radians = controls.current.getAzimuthalAngle();
                setRotationDegree(radians * (180 / Math.PI) - ROTATE_DIFF);
              }}
            />
            <Env />
            <BlockEModel />

            {isLabelShow ? (
              BLOCK_TAGS.length > 0 &&
              BLOCK_TAGS.map((item) => (
                <Html
                  position={item.position}
                  key={item.name}
                  wrapperClass="Block_label"
                >
                  <span onClick={() => dispatch(showHideBlockModel(item.name))}>
                    {item.name}
                  </span>
                </Html>
              ))
            ) : (
              <></>
            )}

            {/* <Html position={[-6, 5, -10]} wrapperClass="Block_label">
              E1
            </Html>
            <Html position={[-2, 5, -5]} wrapperClass="Block_label">
              E2
            </Html>
            <Html position={[2, 5, 0]} wrapperClass="Block_label">
              E3
            </Html>
            <Html position={[5, 5, 4]} wrapperClass="Block_label">
              E4
            </Html>
            <Html position={[10, 5, 10]} wrapperClass="Block_label">
              E5
            </Html> */}
          </Canvas>
        </Suspense>
      </Layout>
    </>
  );
}

export default BlockE;
