import axios from "axios";
import { useRoutes } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Assets/Fonts/Montserrat-Bold.ttf";
import "./Assets/Fonts/Montserrat-Light.ttf";
import "./Assets/Fonts/Montserrat-Medium.ttf";
import "./Assets/Fonts/Montserrat-Regular.ttf";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Contact from "./Pages/Contact";
import Overview from "./Pages/Overview";
import ShivAngan from "./Components/ShivAngan";
import PremAngan from "./Components/PremAngan";
import Connectivity from "./Pages/Connectivity";
import Specifications from "./Pages/Specifications";
import { manageGeoLocation } from "Redux/General/Auth";

import Amenities from "Pages/Amenities";
import BlockA from "Components/3DModels/V2/BlockA";
import BlockB from "Components/3DModels/V2/BlockB";
import BlockC from "Components/3DModels/V2/BlockC";
import BlockD from "Components/3DModels/V2/BlockD";
import BlockE from "Components/3DModels/V2/BlockE";
import BlockF from "Components/3DModels/V2/BlockF";
import BlockG from "Components/3DModels/V2/BlockG";
import BlockH from "Components/3DModels/V2/BlockH";
import BlockI from "Components/3DModels/V2/BlockI";
import BlockJ from "Components/3DModels/V2/BlockJ";
import AmenitiesPage from "Components/3DModels/V2/AmenitiesPage";
import ExteriorLayout from "Components/3DModels/V2/ExteriorLayout";

const router = [
  { path: "/", element: <Overview /> },
  { path: "/contact", element: <Contact /> },
  { path: "/amenities", element: <Amenities /> },
  { path: "/connectivity", element: <Connectivity /> },
  { path: "/specifications", element: <Specifications /> },
  { path: "/3d-model/exterior/block-a", element: <BlockA /> },
  { path: "/3d-model/exterior/block-b", element: <BlockB /> },
  { path: "/3d-model/exterior/block-c", element: <BlockC /> },
  { path: "/3d-model/exterior/block-d", element: <BlockD /> },
  { path: "/3d-model/exterior/block-e", element: <BlockE /> },
  { path: "/3d-model/exterior/block-f", element: <BlockF /> },
  { path: "/3d-model/exterior/block-g", element: <BlockG /> },
  { path: "/3d-model/exterior/block-h", element: <BlockH /> },
  { path: "/3d-model/exterior/block-i", element: <BlockI /> },
  { path: "/3d-model/exterior/block-j", element: <BlockJ /> },
  { path: "/3d-model/amenities-3d", element: <AmenitiesPage /> },
  { path: "/3d-model/interior/shiv-agan", element: <ShivAngan /> },
  { path: "/3d-model/interior/prem-agan", element: <PremAngan /> },
  { path: "/3d-model/exterior/layout", element: <ExteriorLayout /> },
];

const API_URL = "https://ipinfo.io/json"; // The API endpoint

const App = () => {
  const dispatch = useDispatch();
  const { geoLocation } = useSelector((state) => state.Auth);

  const fetchGeoLocation = useCallback(async () => {
    if (geoLocation) return;

    try {
      if (process.env.REACT_APP_IP_INFO_API_KEY) {
        const { data = null } = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_IP_INFO_API_KEY}`,
          },
        });

        if (data) dispatch(manageGeoLocation(data));
      }
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, geoLocation]);

  useEffect(() => {
    fetchGeoLocation();
  }, [fetchGeoLocation]);

  let routes = useRoutes(router);
  return routes;
};

export default App;
