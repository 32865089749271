import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import pin from "../Assets/Images/pin.svg";
import verified from "../Assets/Images/verified.svg";
import { TAG_SHOW, TAG_STATE } from "Helpers/Common";
import { showHideTag } from "Redux/General/ManageTag";

const OverviewComp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const building_details = [
    {
      bhk: "3 BHK",
      rooms: [
        {
          sqft: "695 - 928",
          name: "Tulsi Angan",
          stateName: "tulsiAangan",
          route: "3d-model/exterior/layout",
        },
        {
          sqft: "795 - 864",
          name: "Shiv Angan",
          stateName: "shivAangan",
          route: "3d-model/exterior/layout",
        },
      ],
    },
    {
      bhk: "4 BHK",
      rooms: [
        {
          sqft: "1554 - 1740",
          name: "Prakruti Angan",
          stateName: "prakrutiAangan",
          route: "3d-model/exterior/layout",
        },
        {
          name: "Prem Angan",
          sqft: "1097 - 1206",
          stateName: "premAangan",
          route: "3d-model/exterior/layout",
        },
      ],
    },
    {
      bhk: "4 BHK",
      rooms: [
        {
          name: "Surya Angan",
          sqft: "1112 - 1159",
          stateName: "suryaAangan",
          route: "3d-model/exterior/layout",
        },
        {
          sqft: "1017 - 2248",
          name: "Swapna Angan",
          stateName: "swapnaAangan",
          route: "3d-model/exterior/layout",
        },
      ],
    },
  ];

  const handleClick = (angan) => {
    dispatch(
      showHideTag({
        ...TAG_STATE,
        [angan.stateName]: TAG_SHOW[angan.stateName],
      })
    );

    navigate(angan.route);
  };

  return (
    <>
      <h1>OM Courtyard</h1>
      <div className="mt-4 text-sm lg:text-base ">by Amrut Infracon LLP</div>
      <div className="flex mt-6 gap-1 lg:gap-3 items-center">
        <img
          src={pin}
          alt="pin"
          className="w-[20px] h-[20px] lg:w-[25px] lg:h-[25px] cursor-pointer"
          onClick={() =>
            window.open(
              "https://www.google.com/maps/embed/v1/place?q=Om+Courtyard,+Ahmedabad,+Gujarat,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            )
          }
        />
        <span
          className="text-base lg:text-xl text-[#EF4444] font-normal cursor-pointer"
          onClick={() =>
            window.open(
              "https://www.google.com/maps/embed/v1/place?q=Om+Courtyard,+Ahmedabad,+Gujarat,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            )
          }
        >
          Near Hathijan circle, Ahmedabad
        </span>
      </div>
      <div className="flex mt-9 gap-3 items-center">
        <div className="lg:divide-x-2 flex items-center ">
          <div className=" verified">
            <div className="flex gap-[10px] border-solid ">
              <img src={verified} alt="verified" />
              <span className="font-normal">RERA Approved</span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <Swiper
          slidesPerView={2}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Navigation, Pagination]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            1440: {
              slidesPerView: 2,
            },
          }}
          className="detailsSwiper"
        >
          {building_details.map((item, i) => (
            <SwiperSlide key={`swiperslide- ${i}`}>
              <div
                className="flex flex-col divide-y-[1px] border border-[#E5E7EB] rounded-md"
                style={{ minHeight: "170px" }}
              >
                <div className="p-[12px] bg-[#F9FAFB] font-large font-bold">
                  {item.bhk}
                </div>
                <div className="p-[20px]">
                  <div style={{ textAlign: "right" }}>
                    Plot Area (<span className="font-bold">Sqft</span>)
                  </div>

                  {item.rooms.map((angan) => {
                    return (
                      <div
                        className="grid grid-cols-2 gap-6 mt-3"
                        key={angan.name}
                      >
                        <div>
                          <i
                            className="fa-solid fa-circle mr-2"
                            style={{
                              top: "-2px",
                              fontSize: "7px",
                              position: "relative",
                            }}
                          ></i>
                          <span
                            className="cursor"
                            onClick={() => handleClick(angan)}
                          >
                            {angan.name}
                          </span>
                        </div>
                        <div style={{ marginLeft: "14px" }}>{angan.sqft}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default OverviewComp;
