import React from "react";

import Layout from "./Layout";
import AmmenitiesComponent from "Components/AmmenitiesComponent";

const Ammenities = () => {
  return (
    <Layout>
      <section className="md:h-[655px] mb-[60px] md:mb-[48px] md:mr-[20px] md:overflow-y-auto">
        <AmmenitiesComponent />
      </section>
    </Layout>
  );
};

export default Ammenities;
